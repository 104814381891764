.play-button-bg {
    background: #fae8d7;
    background: linear-gradient(0deg, #fef2e8 0%, #fae8d7 100%);
    border-radius: 23px;
    padding: 8px;
    box-shadow:
        rgba(0, 0, 0, 0.25) 0 6px 40px,
        rgba(0, 0, 0, 0.3) 0 11px 11px;
}

.play {
    position: relative;
    display: inline-block;
    z-index: 1;
    padding: 12px 114px;
    border: 0;
    border-radius: 19px;
    background: rgb(69, 149, 0);
    background: linear-gradient(0deg, rgba(69, 149, 0, 1) 0%, rgba(129, 205, 5, 1) 100%);
    box-shadow:
        rgba(92, 148, 0, 0.1) 0 -2px 2px,
        rgba(255, 255, 255, 0.7) 0 3px 3px inset,
        rgba(0, 0, 0, 0.25) 0 -4px 4px inset,
        rgba(161, 255, 53, 0.59) 0 9px 42px 3px inset,
        rgba(0, 0, 0, 0.45) 0 3px 6px;

    &:hover {
        text-decoration: none;
    }

    &:active {
        top: 1px;
    }

    span {
        position: relative;
        font-size: 50px;
        display: block;
        font-family: PantonExtraBold;
        color: #fff;
        white-space: nowrap;

        &::before {
            content: attr(title);
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            -webkit-text-stroke: 8px #4a8004;
            z-index: -1;
            color: transparent;
            white-space: nowrap;
        }
    }
}

.guest-button-bg {
    background: #fae8d7;
    background: linear-gradient(0deg, #fef2e8 0%, #fae8d7 100%);
    border-radius: 17px;
    padding: 7px;
    box-shadow:
        rgba(0, 0, 0, 0.25) 0 3px 21px,
        rgba(0, 0, 0, 0.3) 0 6px 6px;
}

.play-guest {
    position: relative;
    z-index: 1;
    padding: 8px 16px;
    border: 0;
    border-radius: 14px;
    background: #1685e2;
    background: linear-gradient(0deg, #1685e2 0%, #4ac4f8 100%);
    box-shadow:
        rgba(0, 0, 0, 0.45) 0 2px 3px,
        rgba(92, 148, 0, 0.1) 0 -1px 1px,
        rgba(255, 255, 255, 0.7) 0 2px 2px inset,
        rgba(0, 0, 0, 0.25) 0 -2px 2px inset,
        #5bebfd 0 5px 17px 2px inset;

    &:active {
        top: 1px;
    }

    span {
        position: relative;
        font-size: 28px;
        display: block;
        font-family: PantonExtraBold;
        color: #fff;
        letter-spacing: 0.7px;

        &::before {
            content: attr(title);
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            -webkit-text-stroke: 4px #2375a6;
            z-index: -1;
            color: transparent;
            width: 100%;
            height: 100%;
        }
    }
}

.play-button-bg + .guest-button-bg {
    margin-top: 44px;
}
