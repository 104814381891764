#unsupscribe-page-content {
    background: $blue-gradient;
}

@media all and (max-width: 767px) {
    p.h2 {
        font-size: 1.25rem;
    }

    p.h4,
    button.h4 {
        font-size: 1rem;
    }

    .modal-dialog {
        margin: 1.25rem;
    }

    .modal-content {
        padding: 10px;
    }
}
