// Spacer
// Значения отступов:
// 1 - $spacer/4; 2 - $spacer/2; 3 - $spacer; 4 - $spacer*1.5; 5 - $spacer*3
$spacer: 20px;
$grid-gutter-width: $spacer * 2;

// Fonts
$font-size-base: 1.125rem;
$small-font-size: 1rem;
$font-family-sans-serif: 'Roboto', sans-serif;
$font-weight-bolder: 500;

// Colors (_variables.scss)
$blue: #37c2f8;
$dark-blue: #00649d;
$light-blue: #f7fdff;
$green: #27ae60;
$orange: #f2c94c;
$yellow: $orange;
$purple: #9b4bc7;
$gray-light: #e0e0e0;
$gray: #bdbdbd;
$gray-dark: #828282;
$dark: #4f4f4f;
$white: #fff;

$info: $purple;

// Theme
$body-color: $gray-dark;
$line-height-base: 1.4;
$paragraph-margin-bottom: $spacer;
$header-index-height: 50px;
$link-color: $blue;

$blue-gradient: linear-gradient(180deg, #3ccafe 0%, #1792d3 100%);
$blue-gradient-hover: linear-gradient(180deg, #1d9bda 0%, #0c77ae 100%);
$blue-gradient-active: linear-gradient(180deg, #3bc9fd 0%, #2aafe9 100%);
$blue-gradient-reverse: linear-gradient(180deg, #1792d3 0%, #3ccafe 100%);
$orange-gradient: linear-gradient(180deg, #eeba1c 0%, #e59b25 100%);
$orange-gradient-hover: linear-gradient(180deg, #e59b25 0%, #cb8039 100%);
$orange-gradient-active: linear-gradient(180deg, #ffce2a 0%, #ffb900 100%);
$green-gradient: linear-gradient(180deg, #13fc14 0%, #11f111 23%, #02ae02 98%, #00a600 100%);
$purple-gradient: linear-gradient(180deg, #ad62d7 0%, #8732b6 100%);
$purple-gradient-hover: linear-gradient(180deg, #974dc2 0%, #731ea2 100%);

$secondary: $gray-dark;
$light: $gray-light;

// Typography
$h1-font-size: 2.5rem;
$h2-font-size: 2rem;
$h3-font-size: 1.75rem;
$h4-font-size: 1.25rem;
$headings-color: $dark;

// Modal
$modal-fade-transform: scale(0.8);
$modal-header-border-width: 0;
$modal-header-border-color: transparent;
$modal-content-border-radius: 20px;
$modal-header-padding-y: $spacer;
$modal-header-padding-x: $spacer;
$modal-header-padding: 20px;
$modal-sm: 440px;
$modal-inner-padding: 0.9375rem;
$modal-lg: 760px;

// Buttons
$enable-gradients: true;
$btn-font-size: 1rem;
$btn-font-weight: $font-weight-bolder;
$btn-padding-y: 7px;
$btn-outline-padding-y: 6px;
$btn-padding-x: 28px;
$btn-border-radius: 50px;
$input-btn-focus-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$btn-focus-width: 0;
$btn-border-width: 0;

$btn-font-size-lg: 1.5rem;
$btn-border-radius-lg: 30px;
$btn-padding-y-lg: 6px;
$btn-padding-x-lg: 45px;
$btn-font-size-sm: 0.875rem;
$btn-border-radius-sm: 20px;
$btn-border-radius-lg: 90px;

// Forms
$input-bg: #f9f9f9;
$input-placeholder: $gray;
$input-font-size: 1rem;
$input-border-radius: 10px;
$input-border-width: 0;
$input-border-color: transparent;
$input-padding-y: 0.5rem;
$input-padding-x: 1.875rem;
$form-check-input-gutter: $grid-gutter-width;
$custom-control-indicator-bg: $input-bg;
$custom-control-indicator-checked-bg: $blue;
$custom-control-indicator-border-color: $input-placeholder;
$custom-control-indicator-checked-border-color: $green;
$custom-control-gutter: 0.625rem;
$custom-control-indicator-size: 1.5rem;
$custom-control-indicator-border-width: 1px;
$custom-control-indicator-border-color: $blue;
$custom-control-indicator-checked-border-color: $blue;
$custom-control-indicator-bg: $white;
$custom-control-indicator-active-bg: $white;
$custom-checkbox-indicator-indeterminate-color: $white;
$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,<svg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M14.7046 1.18496C14.6116 1.09123 14.501 1.01684 14.3792 0.966067C14.2573 0.915298 14.1266 0.88916 13.9946 0.88916C13.8626 0.88916 13.7319 0.915298 13.61 0.966067C13.4881 1.01684 13.3775 1.09123 13.2846 1.18496L5.83458 8.64496L2.70458 5.50496C2.60806 5.41172 2.49412 5.33841 2.36926 5.2892C2.24441 5.24 2.11108 5.21587 1.9769 5.21819C1.84272 5.22051 1.71031 5.24924 1.58723 5.30273C1.46415 5.35623 1.35282 5.43344 1.25958 5.52996C1.16634 5.62648 1.09303 5.74042 1.04382 5.86528C0.994618 5.99013 0.970488 6.12346 0.972809 6.25764C0.975131 6.39182 1.00386 6.52423 1.05735 6.6473C1.11085 6.77038 1.18806 6.88172 1.28458 6.97496L5.12458 10.815C5.21754 10.9087 5.32814 10.9831 5.45 11.0338C5.57186 11.0846 5.70257 11.1108 5.83458 11.1108C5.96659 11.1108 6.0973 11.0846 6.21916 11.0338C6.34101 10.9831 6.45162 10.9087 6.54458 10.815L14.7046 2.65496C14.8061 2.56132 14.8871 2.44766 14.9425 2.32117C14.9979 2.19467 15.0265 2.05806 15.0265 1.91996C15.0265 1.78186 14.9979 1.64525 14.9425 1.51875C14.8871 1.39225 14.8061 1.2786 14.7046 1.18496Z' fill='#{$custom-checkbox-indicator-indeterminate-color}' stroke='#{$custom-checkbox-indicator-indeterminate-color}'/></svg>");
$custom-control-indicator-checked-disabled-bg: $gray-light;
$custom-checkbox-indicator-icon-checked-disabled: url("data:image/svg+xml,<svg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M14.7046 1.18496C14.6116 1.09123 14.501 1.01684 14.3792 0.966067C14.2573 0.915298 14.1266 0.88916 13.9946 0.88916C13.8626 0.88916 13.7319 0.915298 13.61 0.966067C13.4881 1.01684 13.3775 1.09123 13.2846 1.18496L5.83458 8.64496L2.70458 5.50496C2.60806 5.41172 2.49412 5.33841 2.36926 5.2892C2.24441 5.24 2.11108 5.21587 1.9769 5.21819C1.84272 5.22051 1.71031 5.24924 1.58723 5.30273C1.46415 5.35623 1.35282 5.43344 1.25958 5.52996C1.16634 5.62648 1.09303 5.74042 1.04382 5.86528C0.994618 5.99013 0.970488 6.12346 0.972809 6.25764C0.975131 6.39182 1.00386 6.52423 1.05735 6.6473C1.11085 6.77038 1.18806 6.88172 1.28458 6.97496L5.12458 10.815C5.21754 10.9087 5.32814 10.9831 5.45 11.0338C5.57186 11.0846 5.70257 11.1108 5.83458 11.1108C5.96659 11.1108 6.0973 11.0846 6.21916 11.0338C6.34101 10.9831 6.45162 10.9087 6.54458 10.815L14.7046 2.65496C14.8061 2.56132 14.8871 2.44766 14.9425 2.32117C14.9979 2.19467 15.0265 2.05806 15.0265 1.91996C15.0265 1.78186 14.9979 1.64525 14.9425 1.51875C14.8871 1.39225 14.8061 1.2786 14.7046 1.18496Z' fill='#{$custom-checkbox-indicator-indeterminate-color}' stroke='#{$custom-checkbox-indicator-indeterminate-color}'/></svg>");
$custom-control-indicator-disabled-bg: $white;
$custom-checkbox-indicator-border-radius: 6px;

// Close
$close-text-shadow: none;
$close-color: $gray-dark;

// Dropdown
$dropdown-link-color: $body-color;
$dropdown-link-hover-color: $dark;
$dropdown-link-hover-bg: $light-blue;
$dropdown-link-active-color: $dropdown-link-hover-color;
$dropdown-link-active-bg: $dropdown-link-hover-bg;

// Alert
$alert-margin-bottom: 0;

// Spinners
$spinner-width: 0.625rem;
$spinner-height: 0.625rem;
$spinner-vertical-align: baseline;
$alert-border-radius: 10px;

// Card
$card-border-radius: 10px;
$card-spacer-x: 15px;

// Image paths
// Пути должны быть указанны собранного бандла с css-кодом.
// См. правила копирования картинок и директорию, куда собираются бандлы.
$commonImageDir: '../../common/images';
$localImageDir: 'images';
