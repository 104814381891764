@-webkit-keyframes slideme {
    0% {
        top: -30px;
        margin-top: 0px;
    }
    30% {
        top: 40%;
        margin-top: 0px;
    }
    100% {
        top: 100%;
        margin-top: 0px;
    }
}

@keyframes slideme {
    0% {
        top: -30px;
        margin-top: 0px;
    }
    30% {
        top: 40%;
        margin-top: 0px;
    }
    100% {
        top: 100%;
        margin-top: 0px;
    }
}

@-webkit-keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.fadeOut {
    animation: fadeOut 1s ease-out 600ms forwards;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    animation: fadeIn 1s ease-out 0ms forwards;
}
