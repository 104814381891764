.list-button,
.list-block {
    color: $gray-dark;
    background: none;
    border: none;

    .social-icon {
        width: 28px;
    }
}

.list-button {
    transition: background 150ms ease-out;

    &:hover {
        background: $light-blue;
    }
}

.lang {
    &.check {
        background-color: $light-blue;

        &::before {
            content: '';
            width: 16px;
            height: 15px;
            display: block;
            position: absolute;
            top: 50%;
            left: 7px;
            transform: translateY(-50%);
            background: url('data:image/svg+xml;utf8,<svg version="1.1" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.861699 7.72833C1.33773 7.44831 2.01699 7.55953 2.37886 7.97675L6.06841 12.2306C6.43028 12.6478 6.33773 13.213 5.8617 13.493C5.38567 13.7731 4.70641 13.6618 4.34454 13.2446L0.654988 8.99079C0.293118 8.57357 0.385665 8.00835 0.861699 7.72833Z" fill="rgb(58 198 251)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M14.8892 0.45764C15.3802 0.710389 15.5077 1.27192 15.1738 1.71186L6.38286 13.2952C6.04898 13.7351 5.38024 13.8869 4.88918 13.6341C4.39813 13.3814 4.27071 12.8198 4.60459 12.3799L13.3955 0.796569C13.7294 0.356634 14.3981 0.20489 14.8892 0.45764Z" fill="rgb(58 198 251)"/></svg>');
        }
    }

    svg {
        vertical-align: top;
    }
}

.password-control {
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;
    width: 20px;
    height: 20px;
    // attr fill is $gray-dark to rgb color
    background: url('data:image/svg+xml;utf8,<svg version="1.1" fill="rgb(130,130,130)" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="1200px" height="1200px" viewBox="0 0 1200 1200"><path d="M669.727,273.516c-22.891-2.476-46.15-3.895-69.727-4.248c-103.025,0.457-209.823,25.517-310.913,73.536 c-75.058,37.122-148.173,89.529-211.67,154.174C46.232,529.978,6.431,577.76,0,628.74c0.76,44.162,48.153,98.67,77.417,131.764 c59.543,62.106,130.754,113.013,211.67,154.174c2.75,1.335,5.51,2.654,8.276,3.955l-75.072,131.102l102.005,60.286l551.416-960.033 l-98.186-60.008L669.727,273.516z M902.563,338.995l-74.927,129.857c34.47,44.782,54.932,100.006,54.932,159.888 c0,149.257-126.522,270.264-282.642,270.264c-6.749,0-13.29-0.728-19.922-1.172l-49.585,85.84c22.868,2.449,45.99,4.233,69.58,4.541 c103.123-0.463,209.861-25.812,310.84-73.535c75.058-37.122,148.246-89.529,211.743-154.174 c31.186-32.999,70.985-80.782,77.417-131.764c-0.76-44.161-48.153-98.669-77.417-131.763 c-59.543-62.106-130.827-113.013-211.743-154.175C908.108,341.478,905.312,340.287,902.563,338.995L902.563,338.995z M599.927,358.478c6.846,0,13.638,0.274,20.361,0.732l-58.081,100.561c-81.514,16.526-142.676,85.88-142.676,168.897 c0,20.854,3.841,40.819,10.913,59.325c0.008,0.021-0.008,0.053,0,0.074l-58.228,100.854 c-34.551-44.823-54.932-100.229-54.932-160.182C317.285,479.484,443.808,358.477,599.927,358.478L599.927,358.478z M768.896,570.513 L638.013,797.271c81.076-16.837,141.797-85.875,141.797-168.603C779.81,608.194,775.724,588.729,768.896,570.513L768.896,570.513z"/></svg>')
        no-repeat center/contain;

    &.view {
        // attr fill is $gray-dark to rgb color
        background: url('data:image/svg+xml;utf8,<svg version="1.1" fill="rgb(130,130,130)" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 488.85 488.85"><path d="M244.425,98.725c-93.4,0-178.1,51.1-240.6,134.1c-5.1,6.8-5.1,16.3,0,23.1c62.5,83.1,147.2,134.2,240.6,134.2 s178.1-51.1,240.6-134.1c5.1-6.8,5.1-16.3,0-23.1C422.525,149.825,337.825,98.725,244.425,98.725z M251.125,347.025 c-62,3.9-113.2-47.2-109.3-109.3c3.2-51.2,44.7-92.7,95.9-95.9c62-3.9,113.2,47.2,109.3,109.3 C343.725,302.225,302.225,343.725,251.125,347.025z M248.025,299.625c-33.4,2.1-61-25.4-58.8-58.8c1.7-27.6,24.1-49.9,51.7-51.7 c33.4-2.1,61,25.4,58.8,58.8C297.925,275.625,275.525,297.925,248.025,299.625z"/></svg>')
            no-repeat center/contain;
    }
}
