.avatar {
    border-radius: 50%;
    overflow: hidden;
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-30px - 50%));
    border: #fff 15px solid;
    display: table;

    &.hide {
        // display: none;
        transform: translate(-50%, 0);
        transition: all 600ms ease-out;
    }

    .avatar-hover {
        opacity: 0;
        cursor: pointer;

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &:hover {
        .avatar-hover {
            opacity: 1;
            background: rgba(0, 0, 0, 0.5);
            transition: opacity 150ms ease-out;
        }
    }
}

.crop-wrapper {
    display: none;
    opacity: 0;

    &.show {
        //display: block;
        opacity: 1;
        transition: opacity 600ms ease-out;
    }
}

.upload-demo {
    width: 220px;
    margin: 0 auto;
}

.croppie-container .cr-slider-wrap {
    width: 100%;
    margin: 15px auto;
    line-height: 1;
}
