header {
    background: $white;
    height: $header-index-height;

    &.logged-in {
        height: 32px;

        .logo {
            width: 95px;
            height: 22px;
        }

        .lang-switcher svg {
            height: 20px;
            width: 21px;
            vertical-align: top;
        }
    }

    .dropdown {
        line-height: 1;

        .dropdown-item {
            font-size: $btn-font-size-sm;
        }
    }
}

hr {
    border-top: $gray-light 1px solid;
}

.close {
    opacity: 1;
}

.modal-content {
    padding: $grid-gutter-width;

    .close {
        right: 0;
        top: 0;
        padding: $grid-gutter-width/2;
        margin: 0;
    }
}

.modal-dialog {
    .bg-head {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background: $blue-gradient-reverse;
        border-radius: $modal-content-border-radius $modal-content-border-radius 0 0;
        z-index: 1;

        img {
            margin: -70px auto 0;
            z-index: 1;
        }
    }

    .bg-head + .modal-content {
        padding-top: 60px;
    }
}

.modal-dialog:not(.privacy-modal) {
    width: 500px;
}

label.small,
.smallest {
    font-size: 0.875rem;
    line-height: 1.3;
}

.form-check-input {
    width: $grid-gutter-width/2;
    height: $grid-gutter-width/2;

    &:checked {
        background: green;
    }
}

.form-group .error {
    font-size: 0.875rem;
    color: #eb5757;
}

.btn-link {
    font-weight: $font-weight-bolder;
    color: $body-color;

    @include hover {
        color: $dark;
        text-decoration: none;
    }

    &:focus,
    &.focus {
        text-decoration: none;
    }
}

.btn-primary {
    background-color: $blue;
    background: $blue-gradient;
    color: $white;
    border-color: transparent;

    &:hover {
        background-color: $blue;
        background: $blue-gradient-hover;
    }

    &:not(:disabled):not(.disabled):active,
    &:focus,
    &.focus {
        background-color: $blue;
        background: $blue-gradient-active;
    }

    &.disabled,
    &:disabled {
        color: $white;
    }
}

.btn-outline-primary,
.btn-outline-secondary {
    padding-top: $btn-outline-padding-y;
    padding-bottom: $btn-outline-padding-y;
}

.btn-outline-primary {
    border-width: 1px;

    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:focus,
    &.focus {
        background-color: transparent;
        color: $dark-blue;
        border-color: $dark-blue;
    }
}

.btn-outline-secondary {
    border-width: 1px;

    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:focus,
    &.focus {
        background-color: transparent;
        color: $dark;
        border-color: $dark;
    }
}

.btn-warning {
    background-color: $orange;
    background: $orange-gradient;
    color: $white;
    border-color: transparent;

    &:hover {
        color: $white;
        background-color: $orange;
        background: $orange-gradient-hover;
        border-color: transparent;
    }

    &:not(:disabled):not(.disabled):active,
    &:focus,
    &.focus {
        color: $white;
        background-color: $orange;
        background: $orange-gradient-active;
    }

    &.disabled,
    &:disabled {
        color: $white;
    }
}

.btn-info {
    background-color: $purple;
    background: $purple-gradient;
    color: $white;
    border-color: transparent;

    &:hover {
        color: $white;
        background-color: $orange;
        background: $purple-gradient-hover;
        border-color: transparent;
    }

    &:not(:disabled):not(.disabled):active,
    &:focus,
    &.focus {
        color: $white;
        background-color: $purple;
        background: $purple-gradient-hover;
    }

    &.disabled,
    &:disabled {
        color: $white;
    }
}

.spinner-grow {
    display: none;
    margin-right: -10px;
}

img {
    max-width: 100%;
    height: auto;
}

// Котрол custom-forms расположенный справа
.custom-control-label.right-control-label {
    &::before,
    &::after {
        left: auto;
        right: 0;
    }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-size: 14px;
}

.custom-checkbox .custom-control-input:disabled {
    ~ .custom-control-label {
        &::before {
            border-color: $gray-light;
        }
    }

    &:checked ~ .custom-control-label {
        &::after {
            background-image: escape-svg($custom-checkbox-indicator-icon-checked-disabled);
        }
    }
}

@media all and (min-width: 992px) {
    .mw-lg-75 {
        max-width: 75% !important;
    }
}

.page-404 {
    font-size: 56px;
    color: #006d8d;
    font-weight: 800;
    -webkit-text-stroke: 1px #fff;
}

input:read-only,
textarea:read-only {
    cursor: not-allowed;
}
