#account {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 640px;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 20px;
    outline: 0;
    padding: 30px;
    margin: 150px auto 60px;

    .profile {
        position: relative;
        flex: 1 1 auto;
        padding: 15px;
    }

    .title {
        color: $gray-light;
    }

    .close {
        top: 30px;
        right: 30px;
        z-index: 10;
    }
}

#shareLink {
    padding-left: 10px;
    padding-right: 10px;

    svg {
        vertical-align: top;
    }
}

#user-name,
#inputName2 {
    font-size: 24px;
    font-weight: 700;
    color: $dark;
}

#user-name {
    line-height: 38px;
}

#inputName2 {
    display: none;
    padding-top: 0;
    padding-bottom: 0;
    height: 38px;
}

#add-email-block,
#change-email-block,
#change-password-block,
#email-connection .actions {
    display: none;
}

.connection-block {
    &.connect {
        background-color: $light-blue;
    }

    .connection-status,
    .user-email {
        line-height: 40px;
    }
}

#email-connection-forms.connect {
    background-color: $light-blue;

    input.form-control {
        background: $white;
        border: 1px solid $gray-light;
    }
}

#add-email.open {
    color: transparent;

    &::after {
        display: block;
        content: '';
        border-bottom: $blue 0.3em solid;
        border-right: 0.3em solid transparent;
        border-top: 0;
        border-left: 0.3em solid transparent;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition:
            color 0.15s ease-in-out,
            background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
    }

    &:hover::after,
    &:active::after,
    &:focus::after {
        border-bottom: $dark-blue 0.3em solid;
    }
}
