.mouse-parallax {
    position: relative;
    overflow: hidden;
}

.game .mouse-parallax-bg,
.game .halloween-bg,
.game .expeditions-bg .blast-bg .chrismas-bg {
    background-image: url(#{$commonImageDir}/bg_game_compressed.jpg);
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    // margin-top: 30px;
}

.support-webp .game .mouse-parallax-bg,
.support-webp .game .halloween-bg,
.support-webp .game .expeditions-bg .blast-bg .chrismas-bg {
    background-image: url(#{$commonImageDir}/bg_game_compressed.webp);
}

.support-avif .game .mouse-parallax-bg,
.support-avif .game .halloween-bg,
.support-avif .game .expeditions-bg .blast-bg .chrismas-bg {
    background-image: url(#{$commonImageDir}/bg_game_compressed.avif);
}

.mouse-parallax-bg,
.halloween-bg,
.expeditions-bg,
.blast-bg,
.chrismas-bg {
    position: absolute;
    background-position: 50% -6px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 105%;
    height: 105%;
}

.main {
    background: url(#{$commonImageDir}/fon-top.png) bottom left no-repeat;
    background-size: contain;
}

.support-webp .main {
    background: url(#{$commonImageDir}/fon-top.webp) bottom left no-repeat;
    background-size: contain;
}

.mouse-parallax-bg {
    background-image: url(#{$commonImageDir}/fon_compressed.jpg);
    transition: all 0.1s ease;
}

.support-webp .mouse-parallax-bg {
    background-image: url(#{$commonImageDir}/fon_compressed.webp);
}
