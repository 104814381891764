.checker {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
    z-index: 1;

    [type='checkbox'] {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + label span {
            background-color: $info;

            &::before {
                transform: translateX(22px);
                background-color: $white;
            }
        }
    }

    label {
        width: 50px;
        height: 28px;

        span {
            background-color: $gray-light;
            border-radius: 25px;
            border: none;
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: background-color 150ms ease-out;

            &::before {
                content: '';
                position: absolute;
                transition: 0.4s;
                border-radius: 20px;
                height: 22px;
                display: block;
                width: 22px;
                bottom: 3px;
                left: 3px;
                background-color: $white;
            }
        }
    }
}
