#play-section {
    height: calc(100vh - #{$header-index-height});
}

#buildings {
    background-color: #17a2b8;
}

#characters {
    picture {
        width: 209px;
        text-align: center;

        img {
            height: 400px;
            width: auto;
        }
    }
}

#letsplay {
    position: relative;
    padding-top: 100px;

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: url(#{$localImageDir}/mvp/letsplay_bg.jpg) no-repeat center/cover;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.support-webp #letsplay,
.support-avif #letsplay {
    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: url(#{$localImageDir}/mvp/letsplay_bg.webp) no-repeat center/cover;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.7;
    }

    h2,
    p {
        text-shadow:
            #1db2da 2px 2px 2px,
            #1db2da -2px -2px 2px,
            #1db2da 2px -2px 2px,
            #1db2da -2px 2px 2px;
    }
}

@media all and (max-width: 1199px) {
    #video iframe {
        width: 680px;
        height: 382px;
    }
}

@media all and (max-width: 991px) {
    #video iframe {
        width: 500px;
        height: 281px;
    }
}

@media all and (max-width: 767px) {
    #video iframe {
        width: calc(100% - #{$spacer * 2});
        max-width: calc(540px - #{$spacer * 2});
        height: calc((100% - #{$spacer * 2}) * 0.5625);
        max-height: calc((540px - #{$spacer * 2}) * 0.5625);
    }
}

.slick-slider + .slick-nav-container {
    .slider-dots-nav {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            padding: 5px;

            &.slick-active button {
                background: $dark;
                transition: background 150ms ease-out;
            }

            button {
                font-size: 0;
                margin: 0;
                padding: 0;
                width: 16px;
                height: 16px;
                border: none;
                border-radius: 50%;
                background: $gray-dark;

                &:focus {
                    outline-color: $dark;
                    outline-width: 2px;
                }
            }
        }
    }

    .slick-arrow {
        position: absolute;
        bottom: 0;
        background: none;
        border: none;
        top: 50%;
        transform: translateY(-50%);

        &:focus {
            outline: none;
        }

        &.slick-prev {
            right: 100%;
            margin-right: 20px;
        }

        &.slick-next {
            left: 100%;
            margin-left: 20px;

            svg {
                transform: rotate(180deg);
            }
        }
    }
}

#reviews {
    .slider img {
        margin-left: auto;
        margin-right: auto;
    }

    .review-avatar {
        flex: 1 0 85px;
        height: max-content;
    }
}
