footer {
    background-color: $dark-blue;
}

.main.halloween,
.main.expeditions {
    background: none;
}

.support-webp .halloween-bg {
    background-image: url(#{$localImageDir}/bg_halloween_compressed.webp);
}

.support-avif .halloween-bg {
    background-image: url(#{$localImageDir}/bg_halloween_compressed.avif);
}

.expeditions-bg {
    background-image: url(#{$localImageDir}/bg_expeditions.jpg);
}

.support-webp .expeditions-bg {
    background-image: url(#{$localImageDir}/bg_expeditions.webp);
}

.blast-bg {
    background-image: url(#{$localImageDir}/bg_blast.jpg);
}

.support-webp .blast-bg {
    background-image: url(#{$localImageDir}/bg_blast.webp);
}

.support-avif .blast-bg {
    background-image: url(#{$localImageDir}/bg_blast.avif);
}

.chrismas-bg {
    background-image: url(#{$localImageDir}/bg_chrismas.jpg);
}

.support-webp .chrismas-bg {
    background-image: url(#{$localImageDir}/bg_chrismas.webp);
}

@media (max-width: 767px) {
    .mouse-parallax-mob,
    .halloween-bg-mob,
    .expeditions-bg-mob,
    .blast-bg-mob,
    .chrismas-bg-mob {
        height: 100%;
        width: 100%;
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
    }

    .mouse-parallax-mob {
        background-image: url(#{$localImageDir}/fon-m.jpg);
    }

    .support-webp .mouse-parallax-mob {
        background-image: url(#{$localImageDir}/fon-m.webp);
    }

    .halloween-bg-mob {
        background-image: url(#{$localImageDir}/bg_halloween_m.jpg);
    }

    .support-webp .halloween-bg-mob {
        background-image: url(#{$localImageDir}/bg_halloween_m.webp);
    }

    .expeditions-bg-mob {
        background-image: url(#{$localImageDir}/bg_expeditions_m.jpg);
    }

    .support-webp .expeditions-bg-mob {
        background-image: url(#{$localImageDir}/bg_expeditions_m.webp);
    }

    .blast-bg-mob {
        background-image: url(#{$localImageDir}/bg_blast_m.jpg);
    }

    .support-webp .blast-bg-mob {
        background-image: url(#{$localImageDir}/bg_blast_m.webp);
    }

    .chrismas-bg-mob {
        background-image: url(#{$localImageDir}/bg_chrismas_m.jpg);
    }

    .support-webp .chrismas-bg-mob {
        background-image: url(#{$localImageDir}/bg_chrismas_m.webp);
    }
}

#game {
    height: calc(100vh - 32px) !important;
    position: relative;
    overflow: hidden;
    width: 100%;
}

#dummy-time-container {
    position: relative;

    #dummy-datetime {
        left: auto !important;
        right: 0;
        top: auto !important;
        bottom: 32px;
    }
}
