#cookie-info-banner,
#cookie-settings {
    opacity: 0.3;
    background: $white;
    border-radius: 20px;
    width: calc(100% - 40px);
    z-index: 9999999;

    &.show {
        opacity: 1;
        transition-delay: 400ms;
    }
}

#cookie-info-banner {
    bottom: 20px;
    right: 20px;
    padding: $spacer;
    max-width: 610px;
    transform: translateY(120%);
    transition-property: transform, opacity;
    transition-duration: 1000ms;
    transition-timing-function: ease-in-out;

    &.show {
        transform: translateY(0);
    }

    picture {
        flex: 1 0 152px;
    }

    .banner-title {
        color: $dark;
    }
}

#cookie-settings {
    top: 50%;
    left: 50%;
    padding: $spacer * 1.5;
    max-width: 560px;
    max-height: calc(100vh - 40px);
    transform: translate(-50%, 100vh);
    transition-property: transform, opacity;
    transition-duration: 1000ms;
    transition-timing-function: ease-in-out;

    &.show {
        transform: translate(-50%, -50%);
    }

    .close {
        top: $spacer * 1.5;
        right: 20px;
    }

    .main-text {
        font-size: 1.25rem;
    }

    .scrollable-container {
        max-height: calc(100vh - 40px - #{$spacer * 6} - 38px);
        overflow-y: scroll;

        &::-webkit-scrollbar-track {
            margin-right: 40px;
        }

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #d8d8d8;
        }
    }

    .cookie-group-list-item {
        border-bottom: $gray-light 1px solid;

        &:first-of-type {
            border-top: $gray-light 1px solid;
        }

        .cookie-group-header {
            height: 70px;

            button {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: none;
                background: none;
                z-index: 1;

                &:not(.collapsed) + div .plus-minus span:last-of-type {
                    display: none;
                }
            }

            .plus-minus {
                width: 16px;
                height: 16px;
                display: block;

                span {
                    background: $gray-dark;
                    position: absolute;
                    display: block;

                    &:first-of-type {
                        width: 100%;
                        height: 2px;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                    }

                    &:last-of-type {
                        width: 2px;
                        height: 100%;
                        left: 50%;
                        top: 0;
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }
}

#onetrust-consent-sdk {
    display: none;
}

#cookie-info-sc-banner {
    bottom: 10px;
    right: 10px;
    transform: translateY(120%);
    opacity: 0.3;
    background: $white;
    padding: $spacer * 1.5;
    border-radius: 10px;
    width: calc(100% - 20px);
    max-width: 500px;
    transition: transform 900ms ease-out 900ms;
    transition-property: transform, opacity;
    transition-duration: 900ms;
    transition-timing-function: ease-out;
    transition-delay: 900ms;
    z-index: 9999999;

    &.show {
        opacity: 1;
        transform: translateY(0);
    }
}
